import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import { styles, FlexBox, Title1, Link, Button } from "../components/styles"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-top: 110px;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Wrapper>
      <Container>
        <Title1 color={styles.colorPrimary} align="left">
          Nie ma takiej strony
        </Title1>
        <Button to="/">Wróć do strony głównej</Button>
      </Container>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
